import { Dialog, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CustomButton } from "..";
import Icons from "../../Assets";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import React from "react";

interface LanguageModalProps {
  open: boolean;
  close: () => void;
  onSubmit: () => void;
  currentLang?: string;
}

const LanguageModal: React.FC<LanguageModalProps> = ({
  currentLang,
  open,
  close,
  onSubmit,
}) => {
  const onDialogClose = () => {
    close();
  };

  const { i18n } = useTranslation();
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      maxWidth="lg"
      classes={{ paper: styles.dialog }}
    >
      <div className={styles.container}>
        <Icons.CloseModalIcon
          onClick={onDialogClose}
          className={styles.closeIcon}
        />
        <div className={styles.title}>Choose language for the form</div>

        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue={currentLang}
          value={currentLang}
        >
          <FormControlLabel
            value="en"
            control={
              <Radio
                defaultChecked={currentLang === "en"}
                onClick={() => changeLanguage("en")}
              />
            }
            label="English"
          />
          <FormControlLabel
            value="sp"
            control={
              <Radio
                defaultChecked={currentLang === "sp"}
                onClick={() => changeLanguage("sp")}
              />
            }
            label="Spanish"
          />
        </RadioGroup>

        <div className={styles.buttonContainer}>
          <CustomButton title={"Generate link"} onClickHandler={onSubmit} />
        </div>
      </div>
    </Dialog>
  );
};

export default LanguageModal;
