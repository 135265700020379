import { SelectChangeEvent } from "@mui/material";
import useResponsive from "../../../Hooks/useResponsive";
import CustomErrorTooltip from "../../CustomErrorTooltip";
import CustomSelect from "../../CustomSelect";
import styles from "./styles.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";

interface FormSelectLineProps {
  id: string;
  counter: string;
  text: string;
  value: string;
  values: Array<{
    text: string;
    value: string;
  }>;
  type?: "text" | "number";
  error?: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  disabled?: boolean;
}

const FormSelectLine: React.FC<FormSelectLineProps> = ({
  id,
  counter,
  text,
  value,
  values,
  onChange,
  error,
  disabled,
}) => {
  const { Tablet } = useResponsive();
  const hasError = error !== undefined && error.length > 0;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div className={styles.counter}>{counter}</div>
        <div className={styles.tabletContainer}>
          <div>{text}</div>
          <Tablet>
            <div className={styles.errorContainer}>
              <CustomSelect
                id={id}
                selectedValue={value}
                onChange={onChange}
                values={values}
                error={hasError}
                disabled={disabled}
                width="100%"
              />
              <CustomErrorTooltip text={error} />
            </div>
          </Tablet>
        </div>
      </div>

      <div className={styles.notTabletContainer}>
        <div className={styles.errorContainer}>
          <CustomSelect
            id={id}
            selectedValue={value}
            onChange={onChange}
            values={values}
            error={hasError}
            disabled={disabled}
            width="100%"
            fullContainer={false}
            className={styles.select}
          />
          <CustomErrorTooltip text={error} />
        </div>
      </div>
    </div>
  );
};
export default FormSelectLine;
